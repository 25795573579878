var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mx-auto" },
        [
          _c(
            "v-card-title",
            { staticClass: "secondary white--text text-subtitle-1" },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("applications.your-application-details")))
              ])
            ]
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-list",
                { attrs: { flat: "", dense: "" } },
                _vm._l(_vm.items, function(item, i) {
                  return _c(
                    "v-list-item",
                    { key: i },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", {
                            attrs: { color: "grey darken-4" },
                            domProps: { textContent: _vm._s(item.icon) }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            staticClass: "grey--text text--darken-4",
                            domProps: { textContent: _vm._s(item.text) }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-alert",
        {
          staticClass: "mt-3",
          attrs: {
            border: "top",
            color: "alternate3 darken-1",
            outlined: "",
            dark: "",
            icon: "mdi-alert-box",
            prominent: ""
          }
        },
        [_c("p", [_vm._v(_vm._s(_vm.$t("applications.auto-saved")))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }