<template>
    <div>
        <v-card class="mx-auto">
            <v-card-title class="secondary white--text text-subtitle-1">
                <span>{{ $t("applications.your-application-details") }}</span>
            </v-card-title>
            <v-card-text>
                <v-list flat dense>
                    <v-list-item v-for="(item, i) in items" :key="i">
                        <v-list-item-icon>
                            <v-icon
                                v-text="item.icon"
                                color="grey darken-4"
                            ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                class="grey--text text--darken-4"
                                v-text="item.text"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
        <v-alert
            border="top"
            color="alternate3 darken-1"
            outlined
            dark
            icon="mdi-alert-box"
            prominent
            class="mt-3"
        >
            <p>{{ $t("applications.auto-saved") }}</p>
        </v-alert>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        application: {
            type: Object,
            required: true,
        },
        applicant: {
            type: Object,
            required: true,
        },
    },
    computed: {
        items() {
            return [
                {
                    text: `${this.$t("applications.application-code")}: ${
                        this.code
                    } `,
                    icon: "mdi-barcode",
                },
                {
                    text: `${this.$t("applications.order-number")}: ${
                        this.applicant.order_id
                    }`,
                    icon: "mdi-clipboard-text",
                },
                {
                    text: `${this.$t("applications.applicants")} ${
                        this.applicantCount
                    }`,
                    icon: "mdi-clipboard-account",
                },
                {
                    text: `${this.$t("applications.cosigners")}: ${
                        this.cosignerCount
                    }`,
                    icon: "mdi-clipboard-check",
                },
            ];
        },
        applicantCount() {
            return this.application.applicants.filter((applicant) =>
                ["Applicant", "Non Applicant"].includes(applicant.type)
            ).length;
        },
        cosignerCount() {
            return this.application.applicants.filter((applicant) =>
                ["Cosigner"].includes(applicant.type)
            ).length;
        },
        code() {
            return this.$_.get(this.application, "meta.code", "");
        },
    },
    data() {
        return {};
    },
    methods: {},
    mounted() {
        // There isn't an interactive component , so complete is just mounted
        this.$emit("update", { complete: true });
    },
};
</script>

<style scoped>
</style>